import { CONTENT_TYPE_EST } from './const';
import _ from 'lodash';
import { removeFile } from '../services/firebase.service';
import {
  getActiveLanguage,
  handleDetectLanguage,
  handleTranslateText,
} from '@/lang/translation';
import { v4 as uuid4 } from 'uuid';
import panzoom from '@panzoom/panzoom';

var fontSizeElement = 4;
let panzoomInstance = null;
export const changeFontsizeElement = (type, editor) => {
  const currentRange = editor.getSelectionRange();

  if (currentRange) {
    editor.setSelectionRange(currentRange);

    let firstElement = currentRange.startContainer.parentNode;
    let style = window.getComputedStyle(firstElement);
    let fontSize = style.getPropertyValue('font-size');

    setFontsizeDefault(fontSize);

    if (
      (fontSizeElement >= 7 && type === 'increase') ||
      (fontSizeElement <= 1 && type === 'decrease')
    )
      return;

    fontSizeElement =
      type === 'increase' ? fontSizeElement + 1 : fontSizeElement - 1;

    const objFontSize = {
      h1: 6,
      h2: 4,
      h3: 2,
      increase: fontSizeElement,
      decrease: fontSizeElement,
    };

    document.execCommand('fontSize', true, objFontSize[type].toString() || '5');
  }
};

const setFontsizeDefault = fontSize => {
  const fontSizeMap = {
    '10px': 1,
    '13px': 2,
    '14px': 2,
    '16px': 3,
    '18px': 4,
    '24px': 5,
    '32px': 6,
    '36px': 6,
    '48px': 7,
  };

  fontSizeElement = fontSizeMap[fontSize] || 5;
};

export const isImage = url => {
  if (!url) return false;
  const imageExtensions = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.bmp',
    '.svg',
    '.webp',
    '.tiff',
  ];
  return imageExtensions.some(extension =>
    url.toLowerCase().includes(extension)
  );
};

export const isVideo = url => {
  if (!url) return false;
  const videoExtensions = [
    '.mp4',
    '.webm',
    '.ogg',
    '.avi',
    '.mov',
    '.wmv',
    '.flv',
    '.mkv',
  ];
  return videoExtensions.some(extension =>
    url.toLowerCase().includes(extension)
  );
};

export const clearSelectionHightLight = () => {
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection) {
    document.selection.empty();
  }
};

export const customButtonForTextFormatBar = (app, isTemplate = false) => {
  return {
    textColor: {
      content: '<i class="f7-icons">pencil</i>',
      async onClick(editor) {
        const currentRange = editor.getSelectionRange();
        const htmlString =
          currentRange.commonAncestorContainer.parentElement.outerHTML || '';

        const colorsDetected = getAllColorsFromHtmlString(htmlString);

        let color = await app.$refs.selectColor.openPopup(colorsDetected);
        if (color && currentRange) {
          editor.setSelectionRange(currentRange);
          document.execCommand('foreColor', true, color);
        }
      },
    },
    Link: isTemplate
      ? {
          content: '<i class="f7-icons">link</i>',
          async onClick(editor) {
            const currentLinkRange = editor.getSelectionRange();
            const textEditorPopover = document.querySelector(
              '.text-editor-popover'
            );
            if (currentLinkRange && textEditorPopover) {
              app.linkRange = currentLinkRange;
              app.$refs.addLinkPopover.open();
              const popover = app.$refs.addLinkPopover.$el;
              const style = window.getComputedStyle(textEditorPopover);
              const editorPopoverLeft = parseInt(style.left, 10);
              const editorPopoverTop = parseInt(style.top, 10);
              popover.style.left = `${editorPopoverLeft}px`;
              popover.style.top = `${editorPopoverTop}px`;
              popover.querySelector('input').focus();
              const popoverAngle = popover.querySelector('.popover-angle');
              if (popoverAngle) {
                popoverAngle.style.display = 'none';
              }
            }
          },
        }
      : {
          content: '<i class="f7-icons">link</i>',
          async onClick(editor) {
            const currentRange = editor.getSelectionRange();
            app.currentRange = currentRange;
            app.openDialogInsertLinkTextEditor();
          },
        },
    increaseFontSize: {
      content: '<div>A+</div>',
      async onClick(editor) {
        changeFontsizeElement('increase', editor);
      },
    },
    decreaseFontSize: {
      content: '<div>A-</div>',
      async onClick(editor) {
        changeFontsizeElement('decrease', editor);
      },
    },
    H1: {
      content:
        '<p style="position: relative; display: inline-block; font-size:20px; font-weight:semibold">H<span style="position: absolute; top: 12px; right: -8px; font-size: 12px;">1</span></p>',
      async onClick(editor) {
        changeFontsizeElement('h1', editor);
      },
    },
    H2: {
      content:
        '<p style="position: relative; display: inline-block; font-size:20px; font-weight:semibold">H<span style="position: absolute; top: 12px; right: -8px; font-size: 12px;">2</span></p>',
      async onClick(editor) {
        changeFontsizeElement('h2', editor);
      },
    },
    H3: {
      content:
        '<p style="position: relative; display: inline-block; font-size:20px; font-weight:semibold">H<span style="position: absolute; top: 12px; right: -8px; font-size: 12px;">3</span></p>',
      async onClick(editor) {
        changeFontsizeElement('h3', editor);
      },
    },
  };
};

export const checkShowContentScreenSingleInEstimate = (type, content) => {
  return type === CONTENT_TYPE_EST.ALL || type === content;
};

export const createMediaArray = item => {
  return item
    .map(attach => {
      if (isImage(attach.url)) {
        return {
          url: attach.url,
          caption: attach.description,
        };
      } else if (isVideo(attach.url)) {
        return {
          html: `<video controls style="width:100%"><source src="${attach.url}"></video>`,
          caption: attach.description,
        };
      }
      return null;
    })
    .filter(media => media !== null);
};

export const openAttackLink = (attach, productPhotos, refs) => {
  if (isImage(attach.url) || isVideo(attach.url)) {
    const index = productPhotos.findIndex(
      photo =>
        (photo.url && photo.url === attach.url) ||
        (photo.html && photo.html.includes(attach.url))
    );
    setTimeout(() => refs.open(index), 100);
  } else {
    window.open(attach.url, '_blank');
  }
};

export const copyAllPhotos = async (photos, copyPhoto) => {
  const newRemainingPhotos = [];
  let promises = [];

  if (photos.length > 0) {
    for (const file of photos) {
      promises.push(
        copyPhoto(file).then(({ fullPath, url }) => {
          newRemainingPhotos.push({
            thumbnailUrl: url,
            thumbnailFullPath: fullPath,
            url: url,
            name: file.name,
            fullPath: fullPath,
          });
        })
      );
    }
    await Promise.all(promises);
    return newRemainingPhotos;
  }
};

export async function handleColorPhoto(props) {
  const addedColors = [];
  let promises = [];
  const { oldData, currentData, uploadPhoto, copyPhoto, isCopied } = props;
  const isColorEqual = (val1, val2) => {
    return val1.id === val2.id;
  };

  let colorsBefore = _.cloneDeep(oldData);
  let colorsAfter = _.cloneDeep(currentData);
  let colorsRemove = _.differenceWith(colorsBefore, colorsAfter, isColorEqual);
  let colorsAdd = _.differenceWith(colorsAfter, colorsBefore, isColorEqual);
  let colorsRemain = _.differenceWith(colorsAfter, colorsAdd, isColorEqual);

  // Upload files
  for (const color of colorsAdd) {
    const photos = [];
    for (const file of color.photos) {
      const { fullPath, url } = await uploadPhoto(file);
      photos.push({
        thumbnailUrl: url,
        thumbnailFullPath: fullPath,
        url: url,
        name: file.info.name,
        fullPath: fullPath,
      });
    }
    addedColors.push({
      id: color.id,
      colorName: color.colorName,
      photos: photos,
    });
  }

  // Delete file in storage
  if (!isCopied) {
    for (const color of colorsRemove) {
      for (const file of color.photos) {
        await removeFile(file.fullPath);
      }
    }
  }

  // colorsRemain
  for (const color of colorsRemain) {
    const oldColor = oldData.find(item => item.id === color.id);
    const oldPhotos = _.cloneDeep(oldColor.photos);
    const currentPhotos = _.cloneDeep(color.photos);
    const removePhotos = _.differenceWith(oldPhotos, currentPhotos, _.isEqual);
    const remainingPhotos = _.differenceWith(
      oldPhotos,
      removePhotos,
      _.isEqual
    );
    const addedPhotos = _.differenceWith(currentPhotos, oldPhotos, _.isEqual);

    if (!isCopied) {
      for (const file of removePhotos) {
        await removeFile(file.fullPath);
      }
    }

    const uploadedPhotos = [];
    for (const file of addedPhotos) {
      const { fullPath, url } = await uploadPhoto(file);
      uploadedPhotos.push({
        thumbnailUrl: url,
        thumbnailFullPath: fullPath,
        url: url,
        name: file.info.name,
        fullPath: fullPath,
      });
    }

    const newRemainingPhotos = [];
    if (isCopied) {
      for (const file of remainingPhotos) {
        promises.push(
          copyPhoto(file).then(({ fullPath, url }) => {
            newRemainingPhotos.push({
              thumbnailUrl: url,
              thumbnailFullPath: fullPath,
              url: url,
              name: file.name,
              fullPath: fullPath,
            });
          })
        );
      }
      await Promise.all(promises);
    }

    color.photos = _.union(
      isCopied ? newRemainingPhotos : remainingPhotos,
      uploadedPhotos
    );
  }

  return {
    updatedData: _.union(colorsRemain, addedColors),
    isCopied: false,
  };
}

export const fetchTranslatedComments = async list => {
  if (_.isEmpty(list)) {
    return [];
  }

  const dataComment = await Promise.all(
    list.map(async item => {
      let languageItem = item.language;

      if (!languageItem && typeof item.htmlContent === 'string') {
        const { message } = await handleDetectLanguage(item.htmlContent);

        languageItem = message;
      }

      const newItem = {
        ...item,
        translatedText: '',
        isShowOrigin: false,
        id: uuid4(),
      };

      if (
        languageItem !== getActiveLanguage() &&
        typeof item.htmlContent === 'string'
      ) {
        const { message } = await handleTranslateText(
          getActiveLanguage() === 'esp' ? 'es' : getActiveLanguage(),
          item.htmlContent
        );
        const newTranslatedText = message.replace('<', ' <');
        return {
          ...newItem,
          translatedText: newTranslatedText,
        };
      } else {
        return newItem;
      }
    })
  );

  return dataComment;
};

export const getAllColorsFromHtmlString = htmlString => {
  const colorRegex = /(?:color:\s*([#\w]+)|<font\s+color=["']?([#\w]+)["']?)/gi;

  const matches = [...htmlString.matchAll(colorRegex)];

  const colors = [
    ...new Set(matches.map(match => match[1] || match[2]).filter(Boolean)),
  ];

  return colors;
};

export const generateTextProcessBar = (
  photoTotal,
  photoCount,
  videoTotal,
  videoCount,
  countLargeFiles
) => {
  return `<span>${photoTotal > 0 ? `${photoCount}/${photoTotal} photos` : ''}
    ${videoTotal > 0 && photoTotal > 0 ? ', ' : ''}
    ${videoTotal > 0 ? `${videoCount}/${videoTotal} videos` : ''}</span>
    ${countLargeFiles > 0 ? `<span style="font-size: 12px; font-weight: 400;">There are ${countLargeFiles} large files that take time to upload.` : ''}
  `;
};

export const getImageDimensions = url => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = function () {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.src = url;
  });
};

export const initZoomImage = imgElement => {
  panzoomInstance = panzoom(imgElement, {
    maxZoom: 3,
    minZoom: 1,
  });
};

export const zoomIn = () => {
  if (panzoomInstance) {
    panzoomInstance.zoomIn();
  }
};
export const zoomOut = () => {
  if (panzoomInstance) {
    panzoomInstance.zoomOut();
  }
};
